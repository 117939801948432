import React from 'react';
import Link from '../components/Link';
import Logo from '../components/Logo';
import styled from 'styled-components';
import tw from 'twin.macro';
import CloseX from '../assets/images/close-x.inline.svg';
const Page = styled.div`
  ${tw`overflow-x-hidden`}
`;

export default (props) => {
  return (
    <Page className="bg-white">
      <section className="relative md:py-10 py-8">
        <CloseX onClick={(e) => typeof window != 'undefined' && window.history.go(-1)} className="absolute top-0 right-0 m-5 text-black hover:text-purple transition-color duration-150 ease-in-out cursor-pointer" style={{ width: '0.85rem' }} />
        <div className="container flex flex-col justify-center items-center">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </section>

      <section className="pt-10 pb-20 text-center">
        <div className="container">
          <div className="flex-grid">
            <div className="w-1/12 hidden md:block">
              <div className="relative h-full">
                <img src={require('assets/images/blocks/block-2.svg')} alt="" className="absolute top-0" style={{ right: '0', top: '35%', minWidth: '130px' }} />
              </div>
            </div>
            <div className="w-10/12" style={{ maxWidth: '800px' }}>
              <header className="mb-10">
                <h1 className="text-58px mb-3">Login</h1>
                <p className="text-20px font-medium">Please get in touch with your account manager if you are unable to login.</p>
              </header>

              <form action="#" className="mx-auto" style={{ maxWidth: '443px' }} onSubmit={(e) => e.preventDefault()}>
                <div className="grid gap-4">
                  <label className="border-2 block">
                    <input type="email" name="email" placeholder="Email Address" />
                  </label>
                  <label className="border-2 block">
                    <input type="password" name="pass" placeholder="Password" />
                  </label>
                </div>

                <label className="text-14px flex items-center justify-center py-6">
                  <input type="checkbox" name="accept" className="mr-3" />
                  <div className="font-medium">Remember me</div>
                </label>

                <button type="submit" className="button button--h57 button--black button--waves w-full">
                  Login
                </button>

                <Link to="/" className="mt-4 button button--orange button--h57 button--waves w-full">
                  <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(.571)" fill="#FFF">
                      <path d="M18.992 9.905c0-.797-.065-1.378-.205-1.98H9.69v3.595h5.34c-.108.894-.69 2.24-1.981 3.144l-.018.12 2.876 2.229.2.02c1.83-1.69 2.885-4.178 2.885-7.128" />
                      <path d="M9.69 19.38c2.616 0 4.812-.862 6.417-2.347l-3.058-2.37c-.818.572-1.917.97-3.36.97-2.562 0-4.736-1.69-5.512-4.027l-.113.01-2.991 2.315-.04.108a9.683 9.683 0 0 0 8.657 5.34" />
                      <path d="M4.177 11.606a5.965 5.965 0 0 1-.323-1.916c0-.668.119-1.314.313-1.917l-.006-.128-3.028-2.352-.1.047A9.699 9.699 0 0 0 0 9.69c0 1.561.377 3.036 1.034 4.35l3.143-2.434" />
                      <path d="M9.69 3.747c1.82 0 3.047.786 3.747 1.442l2.734-2.67C14.491.96 12.306 0 9.69 0a9.683 9.683 0 0 0-8.656 5.34l3.133 2.433c.786-2.336 2.96-4.026 5.523-4.026" />
                    </g>
                  </svg>
                  <div className="mx-4">Sign in with Google </div>
                </Link>
              </form>
            </div>
            <div className="w-1/12  hidden md:block">
              <div className="relative h-full">
                <img src={require('assets/images/blocks/block-1.svg')} alt="" className="absolute" style={{ top: '0%', left: '0', minWidth: '130px' }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Page>
  );
};
